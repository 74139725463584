export default {
    root: {
        class: [
            'iftalabel block relative',

            // Input
            '[&>input]:w-full',
            '[&>input]:pt-5',
            '[&>input]:pb-[6px]',
            '[&>input]:px-2',
            '[&>input]:border',
            '[&>input]:border-solid',
            '[&>input]:border-surface-300',
            '[&>input]:rounded-md',

            '[&>input:focus]:border-surface-950',
            '[&>input:focus]:!ring-[0]',

            // Calendar
            '[&>span>input]:pt-5',
            '[&>span>input]:pb-[6px]',
            '[&>span>input]:px-2',
            '[&>span>input]:w-full',
            '[&>span>input]:border',
            '[&>span>input]:border-solid',
            '[&>span>input]:border-surface-300',
            '[&>span>input]:rounded-md',
            '[&>span>input]:overflow-hidden',
            '[&>span>input]:focus:!ring-0',

            // Dropdown
            '[&>div>span]:pt-5',
            '[&>div>span]:pb-[6px]',
            '[&>div>span]:px-2',
            '[&>div>span]:w-full',
            '[&>div]:border',
            '[&>div]:border-solid',
            '[&>div]:border-surface-300',
            '[&>div]:rounded-md',
            '[&>div>span]:p-0',

            //
            '[&>label]:absolute',
            '[&>label]:top-[6px]',
            '[&>label]:left-2',
            '[&>label]:text-[11px]',
            '[&>label]:leading-none',
            '[&>label]:pointer-events-none',
            '[&>label]:z-[11]',

            // Base Label Appearance
            '[&>*:last-child]:text-surface-900/60 dark:[&>*:last-child]:text-white/60',

            // Position for labels following textareas
            '[&>textarea~label]:top-4',
        ]
    }
};
