import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import PrimeVue from 'primevue/config';
import { ru } from './Lang/primevue/ru.json';
import "primeicons/primeicons.css";
import Lara from './Presets/lara';
import Aura from './Presets/aura';
import AuraTheme from '@primevue/themes/aura';



import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import DialogService from 'primevue/dialogservice';
import VueResizeObserver from "vue-resize-observer";
import ToastService from 'primevue/toastservice';

import store from '@/store';
import { createYmaps } from 'vue-yandex-maps';

import Tooltip from 'primevue/tooltip';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import { usePage } from "@inertiajs/vue3";

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(store)
            .use(PrimeVue, {
                unstyled: true,
                ripple: true,
                locale: ru,
                pt: Aura
                // theme: {
                //     preset: AuraTheme,
                //     options: {
                //         prefix: 'p',
                //         darkModeSelector: '.dark',
                //         cssLayer: false
                //     }
                // }
            })
            .use(ZiggyVue)
            .use(VueResizeObserver)
            .use(DialogService)
            .use(ToastService)
            .use(createYmaps({
              apikey: '1775cc0b-b875-4589-977c-d378cfe05814',
            }))
            .directive('tooltip', Tooltip)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});

function setViewHeight() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}
setViewHeight();

window.addEventListener('resize', () => {
    setViewHeight()
});

//!!!

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register('/sw.js').then(function(registration) {
            console.log('Service Worker зарегистрирован с областью:', registration.scope);
        }).catch(function(error) {
            console.error('Ошибка регистрации Service Worker:', error);
        });


        if ('PushManager' in window) {
            navigator.serviceWorker.ready.then(function(registration) {
                return registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: 'BPdcsFbqiCtUxS4dwfN9BGfxZ_9WmxpKRH9j5Re8UM0twzeKTGaxqOS4WhTVxoWXyzSNcU5IzA0xPwxxtCc4iho'
                });
            }).then(function(subscription) {
                console.log('Подписка на уведомления:', subscription);
                // Здесь вы можете отправить подписку на сервер для сохранения
                axios.post("/subscription",
                    {
                        subscription: subscription,
                        user_id: usePage().props.auth.user.id
                    });
            }).catch(function(error) {
                console.error('Ошибка подписки на уведомления:', error);
            });
        }

    });


}




