import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        isSidebarOpen: true,
        sidebarMobileScreen: 1024,
        isAsideOpen: false,
        isDarkTheme: false,
        isCollapseModal: false,
        isPageLoading: false,
        sidebarOptions: {},
        deal: {},
        lastOpenedDeal: -1,
        lastClosedDeal: -1,
    },
    plugins: [createPersistedState()],
    actions: {
        setNewDealToStore(context, deal) {
          context.commit("setNewDealToStore", deal);
        }
    },
    mutations: {
        setNewDealToStore(state, deal) {
          state.deal = null;
          state.lastOpenedDeal > 0 ? state.lastClosedDeal = state.lastOpenedDeal : state.lastClosedDeal = deal.id;
          
          setTimeout(() => {
            state.deal = deal
            deal ? state.lastOpenedDeal = deal.id : null;
          },0)
        },
        toggleTheme(state) {
            document.documentElement.classList.toggle("dark");
            state.isDarkTheme = !state.isDarkTheme;
        },
        toggleSidebar(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        toggleAside(state) {
            state.isAsideOpen = !state.isAsideOpen;
        },
        closeAside(state) {
            state.isAsideOpen = false;
        },
        openAside(state) {
            state.isAsideOpen = true;
        },
        setSidebar(state, value) {
            state.isSidebarOpen = value;
        },
        setPageLoading(state, value) {
            state.isPageLoading = value;
        },
        setSidebarOptions(state,value) {
            for (let prop of Object.keys(value)) {
                state.sidebarOptions[prop] = state.sidebarOptions[prop] ? state.sidebarOptions[prop] : {};
                state.sidebarOptions[prop] = Object.assign(state.sidebarOptions[prop],value[prop]);
            }
        },
    },
    getters: {
        isAsideOpen(state) {
            return state.isAsideOpen;
        },
        isSidebarOpen(state) {
            return state.isSidebarOpen;
        },
        isCollapseModal(state) {
            return state.isCollapseModal;
        },
        getSidebarOptions(state) {
            return state.sidebarOptions;
        },
        getDeal(state) {
            return state.deal;
        },
        getLastDeal(state){
          return state.lastOpenedDeal;
        },
        getLastClosed(state){
          return state.lastClosedDeal;
        }
    },
});
